
import { createRouter, createWebHashHistory } from 'vue-router';

import { useAuth } from 'src/composables/auth';


let routes = [
	//Dashboard routes


//dashboard routes
			{
				path: '/dashboard/:index?/:fieldName?/:fieldValue?',
				name: 'dashboardlist',
				component: () => import('./pages/dashboard/list.vue'), 
				props: true
			},
			
	
			{ 
				path: '/dashboard/view/:id', 
				name: 'dashboardview', 
				component: () => import('./pages/dashboard/view.vue'), 
				props: true
			},
	
			{ 
				path: '/dashboard/add', 
				name: 'dashboardadd', 
				component: () => import('./pages/dashboard/add.vue'), 
				props: true
			},
	
			{ 
				path: '/dashboard/edit/:id', 
				name: 'dashboardedit', 
				component: () => import('./pages/dashboard/edit.vue'), 
				props: true
			},
		

//form_p2h routes
			{
				path: '/form_p2h/:index?/:fieldName?/:fieldValue?',
				name: 'form_p2hlist',
				component: () => import('./pages/form_p2h/list.vue'), 
				props: true
			},
			
	
			{ 
				path: '/form_p2h/view/:id', 
				name: 'form_p2hview', 
				component: () => import('./pages/form_p2h/view.vue'), 
				props: true
			},
	
			{ 
				path: '/form_p2h/add', 
				name: 'form_p2hadd', 
				component: () => import('./pages/form_p2h/add.vue'), 
				props: true
			},
	
			{ 
				path: '/form_p2h/edit/:id', 
				name: 'form_p2hedit', 
				component: () => import('./pages/form_p2h/edit.vue'), 
				props: true
			},
		

//users routes
			{
				path: '/users/:index?/:fieldName?/:fieldValue?',
				name: 'userslist',
				component: () => import('./pages/users/list.vue'), 
				props: true
			},
			
	
			{ 
				path: '/users/view/:id', 
				name: 'usersview', 
				component: () => import('./pages/users/view.vue'), 
				props: true
			},
	
			{ 
				path: '/account/edit', 
				name: 'usersaccountedit', 
				component: () => import('./pages/account/accountedit.vue'), 
				props: true
			},
	
			{ 
				path: '/account', 
				name: 'usersaccountview', 
				component: () => import('./pages/account/accountview.vue'), 
				props: true
			},
	
			{ 
				path: '/users/add', 
				name: 'usersadd', 
				component: () => import('./pages/users/add.vue'), 
				props: true
			},
	
			{ 
				path: '/users/edit/:id', 
				name: 'usersedit', 
				component: () => import('./pages/users/edit.vue'), 
				props: true
			},
		

	
	
//Password reset routes
			{ 
				path: '/index/forgotpassword', 
				name: 'forgotpassword', 
				component: () => import('./pages/index/forgotpassword.vue'), 
				props: true
			},
			{ 
				path: '/index/resetpassword', 
				name: 'resetpassword', 
				component: () => import('./pages/index/resetpassword.vue'), 
				props: true
			},
			{ 
				path: '/index/resetpassword_completed', 
				name: 'resetpassword_completed', 
				component: () => import('./pages/index/resetpassword_completed.vue'), 
				props: true
			},
	
	
	
	{ 
		path:  '/error/forbidden', 
		name: 'forbidden', 
		component: () => import('./pages/errors/forbidden.vue'),
		props: true
	},
	{ 
		path: '/error/notfound', 
		name: 'notfound',
		component: () => import('./pages/errors/pagenotfound.vue'),
		props: true
	},
	{
		path: '/:catchAll(.*)', 
		component: () => import('./pages/errors/pagenotfound.vue')
	}
];

export default () => {
	
const auth = useAuth();

	
	const user = auth.user;
	if(user){
		routes.push({ path: '/', alias: '/home', name: 'home', component: () => import(`./pages/home/home.vue`) });
	}
	else{
		routes.push({ path: '/', alias: '/index', name: 'index', component: () => import('./pages/index/index.vue') });
	}

	const router = createRouter({
		history: createWebHashHistory(),
		routes,
		scrollBehavior(to, from, savedPostion){
			//if(savedPostion) return savedPostion;
			return { x:0, y:0 }
		}
	});
	
	router.beforeEach((to, from, next) => {
		const user = auth.user;
		let path = to.path;
		let authRequired = auth.pageRequiredAuth(path);
		
		//authenticate user
		if (authRequired && !user) {
			//user is not logged. redirect to login
			return next({ path: '/',  query: { nexturl: to.fullPath } });
		}

		if(user && to.name == "index"){
			//already logged in, show home when try to access index page
			return next({ path: "/home"});
		}

		//navigate to redirect url if available
		if(to.name == "home" && to.query.nexturl){
			return next({ path: to.query.nexturl});
		}

		//close dialog from previous page
		//store.dispatch('app/closeDialogs');

		//continue to specified route
		next();
	});

	return router;
}
