
export const AppMenus = {
    
	navbarTopRightItems: [],
	navbarTopLeftItems: [],
	navbarSideLeftItems: [
  {
    "to": "/home",
    "label": "Home",
    "icon": "pi pi-th-large text-primary",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/users",
    "label": "Users",
    "icon": "pi pi-user text-primary",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/form_p2h",
    "label": "History P2H",
    "icon": "pi pi-history text-primary",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/dashboard",
    "label": "Settings",
    "icon": "pi pi-cog text-primary",
    "iconcolor": "",
    "target": "",
    
  }
],
	q1Items: [    
{value: "Ya", label: "Ya"},
	{value: "Tidak", label: "Tidak"}
    ],

    exportFormats: {
        print: {
			label: 'Print',
            icon: 'pi pi-print',
            type: 'print',
            ext: '',
        },
        pdf: {
			label: 'Pdf',
			
            icon: 'pi pi-file-pdf',
            type: 'pdf',
            ext: 'pdf',
        },
        excel: {
			label: 'Excel',
            icon: 'pi pi-file-excel',
            type: 'excel',
            ext: 'xlsx',
        },
        csv: {
			label: 'Csv',
            icon: 'pi pi-table',
            type: 'csv',
            ext: 'csv',
        },
    },
    
}